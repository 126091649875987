@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

@font-face {
    font-family: Alien;
    src: url(/static/media/Alien-Skyline.16a1556e.otf) format("opentype");
}

.navigation {
    text-align: center;
    position: fixed;
    width: calc(100% - 106px);
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 50;
    justify-content: space-between;
    padding: 0 46px 0 60px;
    color: #ff626f;
    font-size: 14px;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.2);
}

.name-and-avatar-container {
    display: flex;
    align-items: center;
    text-align: left;
}

.name {
    font-family: Alien;
    font-size: 32px;
    letter-spacing: -5px;
}

.name-and-avatar-container .name span:first-of-type {
    color: rgba(255, 255, 255, 0.75);
}

.nav-buttons {
    display: flex;
}

.nav-buttons a {
    text-decoration: none;
}

.service-name {
    padding-left: 2px;
    color: white;
    opacity: 0.4;
}

.menu-button-container {
    display: none;
}

.drawer-content {
    width: 275px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    color: rgba(255, 255, 255, 0.75);
    position: relative;
}

.language-selector-container-in-menu {
    position: absolute;
    bottom: 10px;
}

.language-selector-container-in-menu button {
    color: rgba(255, 255, 255, 0.75);
}

.language-selector-container-in-menu button[active=true] {
    color: #ff626f;
}

@media screen and (max-width: 1200px) {
    .navigation {
        padding: 0 20px 0 20px;
        width: calc(100% - 40px);
    }

    .navigation .nav-buttons {
        display: none;
    }

    .navigation .menu-button-container {
        display: block;
    }
}

.app .wrapper {
    z-index: 5;
    position: relative;
    margin-bottom: 80px;
}

.header {
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: radial-gradient(
        circle,
        #1c1c1c,
        #181818,
        #151515,
        #101010,
        #0b0b0b
    );
    overflow: hidden;
}

.maxi-photo {
    position: absolute;
    bottom: 0;
    left: calc(50% - 600px);
    width: 600px;
    height: 90%;
    background-image: url(/static/media/djmaxi.869746bf.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 5;
}

.title-container {
    font-family: Alien;
    position: absolute;
    width: calc(100% - 170px);
    left: 170px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 820px;
    line-height: 280px;
    letter-spacing: -230px;
}

.title-container div {
    width: 650px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dj-maxy.appspot.com/o/loop.gif?alt=media&token=913d6bfc-503a-4780-8e3e-c73d27e09915");
    background-size: cover;
    -webkit-background-clip: text;
    color: rgba(255, 255, 255, 0.15);
    background-clip: text;
}

.about {
    background-image: radial-gradient(
        circle,
        #f6f6f6,
        #eeeeee,
        #e7e7e7,
        #dfdfdf,
        #d8d8d8
    );
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 80px 30px;
    color: #1c1c1c;
}

.about-title {
    font-size: 40px;
    margin-bottom: 30px;
    line-height: 40px;
}

.about .about-text-and-btn {
    display: flex;
    flex-direction: column;
    margin-right: 110px;
}

.about .text {
    font-size: 18px;
    margin-bottom: 30px;
    max-width: 600px;
    line-height: 40px;
    font-style: italic;
}

.about .maxy-img {
    background-image: url(/static/media/maxy_aboutme.41bfa8b0.jpg);
    height: 350px;
    width: 530px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 1);
}

.social-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100vh;
    justify-content: center;
    left: 60px;
}

.language-selector-container {
    position: absolute;
    right: 60px;
    bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
}

.events {
    background-color: #212121;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 20px;
}

.events .text {
    z-index: 10;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.events .party-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dj-maxy.appspot.com/o/party.jpg?alt=media&token=468cc748-e60d-4e31-bb6e-249f6e6ef170");
    opacity: 0.1;
    background-attachment: fixed;
}

.events .text .title {
    font-size: 40px;
    margin-bottom: 30px;
    line-height: 40px;
}

.events .text .description {
    font-size: 20px;
    line-height: 40px;
    max-width: 800px;
    font-style: italic;
}

.events .text .description .section {
    margin: 30px 0;
}

.events .text .description .section:last-of-type {
    margin-bottom: 0;
}

.tech {
    position: relative;
    background-image: radial-gradient(
        circle,
        #f6f6f6,
        #eeeeee,
        #e7e7e7,
        #dfdfdf,
        #d8d8d8
    );
}

.carousel-item {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 470px;
    align-items: center;
    transform: translateZ(0);
}

.arrow-contrainer {
    height: calc(100% - 52px);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    margin: 0 40px;
}

.arrow-contrainer.left {
    left: 0;
}

.arrow-contrainer.right {
    right: 0;
}

.carousel-item .img-container {
    height: 100%;
    z-index: 5;
}

.carousel-item img {
    height: calc(100% - 120px);
    margin: 35px 0 60px;
}

.carousel-item .text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #5e5e5e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 15px 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
}

.contact {
    padding: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: #212121;
    color: rgba(255, 255, 255, 0.75);
}

.contact .title {
    font-size: 40px;
    margin-bottom: 30px;
    line-height: 40px;
    z-index: 2;
}

.contact .email-container {
    margin-bottom: 20px;
}

.contact .contact-text {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 40px;
    max-width: 800px;
    font-style: italic;
    z-index: 2;
}

.contact .party-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dj-maxy.appspot.com/o/party2.jpg?alt=media&token=3afca1d9-80e6-4cc8-ad49-075e5f905c0d");
    opacity: 0.1;
    background-attachment: fixed;
    background-position: bottom;
}

.footer {
    position: fixed;
    background-image: radial-gradient(
        circle,
        #f6f6f6,
        #eeeeee,
        #e7e7e7,
        #dfdfdf,
        #d8d8d8
    );
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.footer-social {
    margin-left: 50px;
}

.gallery {
    background-image: radial-gradient(
        circle,
        #1c1c1c,
        #181818,
        #151515,
        #101010,
        #0b0b0b
    );
    padding: 80px 0;
}

@media screen and (max-width: 1200px) {
    .maxi-photo {
        left: calc(50% - 200px);
        width: 400px;
        height: 560px;
        z-index: 0;
    }

    .title-container {
        display: none;
    }

    .social-container {
        right: 20px;
        left: unset;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        top: 100px;
        height: auto;
    }

    .language-selector-container {
        display: none;
    }

    .about {
        flex-direction: column;
        text-align: center;
        padding: 50px 30px;
    }

    .about .about-text-and-btn {
        margin-right: 0;
    }

    .about .text {
        font-size: 16px;
        line-height: 30px;
    }

    .about .maxy-img {
        width: 100%;
        height: 240px;
        max-width: 390px;
    }

    .events {
        padding: 50px 30px;
        height: auto;
    }

    .events .text .description {
        font-size: 16px;
        line-height: 30px;
    }

    .carousel-item {
        height: 275px;
    }

    .arrow-contrainer {
        margin: 0 10px;
    }

    .carousel-item .text-container {
        font-size: 14px;
    }

    .gallery {
        padding: 30px;
    }

    .contact {
        padding: 50px 30px;
        height: auto;
    }

    .contact .contact-text {
        font-size: 16px;
        line-height: 30px;
    }
}

