.navigation {
    text-align: center;
    position: fixed;
    width: calc(100% - 106px);
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 50;
    justify-content: space-between;
    padding: 0 46px 0 60px;
    color: #ff626f;
    font-size: 14px;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.2);
}

.name-and-avatar-container {
    display: flex;
    align-items: center;
    text-align: left;
}

.name {
    font-family: Alien;
    font-size: 32px;
    letter-spacing: -5px;
}

.name-and-avatar-container .name span:first-of-type {
    color: rgba(255, 255, 255, 0.75);
}

.nav-buttons {
    display: flex;
}

.nav-buttons a {
    text-decoration: none;
}

.service-name {
    padding-left: 2px;
    color: white;
    opacity: 0.4;
}

.menu-button-container {
    display: none;
}

.drawer-content {
    width: 275px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    color: rgba(255, 255, 255, 0.75);
    position: relative;
}

.language-selector-container-in-menu {
    position: absolute;
    bottom: 10px;
}

.language-selector-container-in-menu button {
    color: rgba(255, 255, 255, 0.75);
}

.language-selector-container-in-menu button[active=true] {
    color: #ff626f;
}

@media screen and (max-width: 1200px) {
    .navigation {
        padding: 0 20px 0 20px;
        width: calc(100% - 40px);
    }

    .navigation .nav-buttons {
        display: none;
    }

    .navigation .menu-button-container {
        display: block;
    }
}
