@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

@font-face {
    font-family: Alien;
    src: url("./Static/Fonts/Alien-Skyline.otf") format("opentype");
}
